<template>
  <div>
    <router-view> </router-view>
    <van-tabbar v-model="active">
      <van-tabbar-item>
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon.home.active : icon.home.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>课程</span>
        <template #icon="props">
          <img :src="props.active ? icon.baby.active : icon.baby.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>分类</span>
        <template #icon="props">
          <img :src="props.active ? icon.list.active : icon.list.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item badge="3">
        <span>购物车</span>
        <template #icon="props">
          <img :src="props.active ? icon.cart.active : icon.cart.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon.me.active : icon.me.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
export default {
  data() {
    return {
      active: 0,
      icon: {
        home: {
          name: "首页",
          active:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044587550.png",
          inactive:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044567678.png",
        },
        class: {
          name: "课程",
          active:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044575950.png",
          inactive:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044524976.png",
        },
        list: {
          name: "分类",
          active:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044522430.png",
          inactive:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044598191.png",
        },
        cart: {
          name: "购物车",
          active:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044591772.png",
          inactive:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044523337.png",
        },
        me: {
          name: "我的",
          active:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044590454.png",
          inactive:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044593826.png",
        },
      },
    };
  },
  components: {
    vanTabbar: Tabbar,
    vanTabbarItem: TabbarItem,
  },
};
</script>

<style lang="scss" scoped></style>
